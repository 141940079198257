import React, { useState, useEffect } from "react"

export const SiteContext = React.createContext()

const Provider = (props) => {
  // Area + capacity

  let ancestors = null
  let title = null
  let currentLanguage = "en"

  const [searchOpen, setSearchOpen] = useState(false)

  const [filtersWereUsed, setFiltersWereUsed] = useState(false)
  const [sizeFiltersWereUsed, setSizeFiltersWereUsed] = useState(false)

  const [activeCity, setActiveCity] = useState(false)
  const [activeCampuses, setActiveCampuses] = useState({})
  const [activeCapacity, setActiveCapacity] = useState(false)
  const [activeAreaSize, setActiveAreaSize] = useState(false)
  const [activeRoomCapacity, setActiveRoomCapacity] = useState(false)

  const [activeCapacityTimeStamp, setActiveCapacityTimeStamp] = useState(0)
  const [activeAreaSizeTimeStamp, setActiveAreaSizeTimeStamp] = useState(0)

  const [activeRoomTypeEN, setActiveRoomTypeEN] = useState(false)
  const [activeRoomTypeFI, setActiveRoomTypeFI] = useState(false)
  const [activeRoomTypeEE, setActiveRoomTypeEE] = useState(false)
  const [activeRoomTypeLT, setActiveRoomTypeLT] = useState(false)
  const [activeRoomTypeLU, setActiveRoomTypeLU] = useState(false)
  const [activeRoomTypeLU_FR, setActiveRoomTypeLU_FR] = useState(false)
  const [activeRoomTypeNO, setActiveRoomTypeNO] = useState(false)
  const [activeRoomTypeSE, setActiveRoomTypeSE] = useState(false)

  const [advertisement, setAdvertisement] = useState(null)
  const [dismissedAds, setDismissedAds] = useState([])

  useEffect(() => {
    if (typeof window !== "undefined") {
      // UTM Parameters

      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)

      const utmObject = {
        gclid: urlParams.get("gclid"),
        utm_id: urlParams.get("utm_id"),
        utm_source: urlParams.get("utm_source"),
        utm_medium: urlParams.get("utm_medium"),
        utm_campaign: urlParams.get("utm_campaign"),
        utm_term: urlParams.get("utm_term"),
        utm_content: urlParams.get("utm_content"),
      }

      Object.keys(utmObject).forEach((key) => {
        if (utmObject[key]) {
          localStorage.setItem(`technopolis_${key}`, utmObject[key])
        }
      })

      // Previously dismissed advertisements.
      const dismissedAdsinLocalStorage = getWithExpiry(
        "technopolis_dismissed_ads"
      )

      if (dismissedAdsinLocalStorage && dismissedAdsinLocalStorage.length > 0) {
        setDismissedAds(dismissedAdsinLocalStorage)
      }
    }
  }, [])

  // Get items from localStorage with their expiration stamp.
  const getWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key)

    if (!itemStr) {
      return null
    }

    const item = JSON.parse(itemStr)
    const now = new Date()

    // Compare the expiry time of the item with the current time.
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage.
      localStorage.removeItem(key)
      return null
    }
    return item.value
  }

  return (
    <SiteContext.Provider
      value={{
        ancestors,
        title,
        currentLanguage,
        searchOpen,
        setSearchOpen,
        filtersWereUsed,
        setFiltersWereUsed,
        sizeFiltersWereUsed,
        setSizeFiltersWereUsed,
        activeCapacityTimeStamp,
        setActiveCapacityTimeStamp,
        activeAreaSizeTimeStamp,
        setActiveAreaSizeTimeStamp,
        activeCity,
        setActiveCity,
        activeCampuses,
        setActiveCampuses,
        activeCapacity,
        setActiveCapacity,
        activeAreaSize,
        setActiveAreaSize,
        activeRoomCapacity,
        setActiveRoomCapacity,
        activeRoomTypeEN,
        setActiveRoomTypeEN,
        activeRoomTypeFI,
        setActiveRoomTypeFI,
        activeRoomTypeEE,
        setActiveRoomTypeEE,
        activeRoomTypeLT,
        setActiveRoomTypeLT,
        activeRoomTypeLU,
        setActiveRoomTypeLU,
        activeRoomTypeLU_FR,
        setActiveRoomTypeLU_FR,
        activeRoomTypeNO,
        setActiveRoomTypeNO,
        activeRoomTypeSE,
        setActiveRoomTypeSE,
        advertisement,
        setAdvertisement,
        dismissedAds,
        setDismissedAds,
      }}
    >
      {props.children}
    </SiteContext.Provider>
  )
}
// eslint-disable-next-line import/no-anonymous-default-export
export default ({ element }) => <Provider>{element}</Provider>
