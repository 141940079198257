/**
 * lazy loader for images
 *
 * See: https://github.com/aFarkas/lazysizes
 */
import "lazysizes"
import "lazysizes/plugins/attrchange/ls.attrchange"
import "lazysizes/plugins/parent-fit/ls.parent-fit"
import "lazysizes/plugins/progressive/ls.progressive.min"

/**
 * Provider
 *
 * See: https://www.digitalocean.com/community/tutorials/gatsbyjs-state-management-in-gatsby
 */
import Provider from "@context/Provider"

export const wrapRootElement = Provider

/**
 * (removing the) no-js class for body, useful with e.g. FadeInBox and FadeInUpBox
 *
 * See: https://curtistimson.co.uk/post/gatsbyjs/add-body-class-gatsbyjs-fouc/
 */

export const onClientEntry = () => {
  window.addEventListener("load", () => {
    document.body.className = document.body.className.replace(/\bno-js\b/, "")
  })
}
